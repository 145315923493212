import React from "react"
import Reusable from "../../components/articleLayout"
import OXTIcon from "../../images/oxt.png"
import OXTCard from "../../images/TwitterCards/OXTCard.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const OrchidOXT52 = () => (
  <Layout>
    <SEO
      title="Earn $52 OXT on Coinbase"
      description="Learn how to earn $52 worth of OXT on Coinbase."
      article={true}
      image={OXTCard}
      pathname="/crypto/orchid-oxt-$52"
    />
    <Reusable
      CoinbaseRef={true}
      Logo={OXTIcon}
      ImageAlt="OXT Logo"
      From="www.coinbase.com"
      Title="Earn $52 worth of Orchid OXT"
      Name="Orchid"
      Ticker="OXT"
      Value="$52"
      Address="https://coinbase.com/earn/oxt/invite/d7sjm9hx"
      Step1="Make sure you are signed into your Coinbase account in a separate tab."
      Step3="Click the “Claim Now” button on this page."
      Step4="You will be redirected to Coinbase, accept the invite. (Depending on supply, you may be put on a waitlist. If you are, you will receive an email from Coinbase when it’s your turn.)"
      Step5="Watch Lesson 1, answer the question, receive $4 worth of OXT."
      Step6="Watch Lesson 2, answer the question, receive $4 worth of OXT."
      Step7="Watch Lesson 3, answer the question, receive $4 worth of OXT."
      Step8="Now share your invite link with 4 friends and get an additional $10 for each friend that joins."
      Step11="Congratulations! You just earned $52 worth of Orchid OXT."
      Introduction="Coinbase Earn is a new program offered by Coinbase to their users. They have a variety of coins to choose from with the potential of earning up to $186 in cryptocurrency. Most of their courses are simple; watch a short video, answer a question and get rewarded instantly. Not a bad way to add to your portfolio."
    />
  </Layout>
)

export default OrchidOXT52
